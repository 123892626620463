
bcore.pages['florist'].on_page_load = async function (wrapper) {
	const page = await bcore.ui.make_app_page_async({
		parent: wrapper,
		title: 'Florist',
		single_column: true,
		simple: true
	});

	const $toolsBtn = $('<b-button id="florist-properties-btn" size="sm" class="m-0 p-2h" component="b-florist-properties-panel" icon="artboard" padding="1h"></b-button>');
	const $dataBtn = $('<b-button id="florist-data-btn" size="sm" class="m-0 p-2h" component="b-florist-data-panel" icon="database" padding="1h"></b-button>');

	const toolsPanel = document.getElementById("tools-panel");
	toolsPanel.appendChild($toolsBtn[0]);
	toolsPanel.appendChild($dataBtn[0]);


	$(wrapper).on('hide', () => $toolsBtn.fadeOut('fast'));
	$(wrapper).on('show', () => {
		$toolsBtn.fadeIn('fast');
	});

	page.hide_header();
	page.wrapper.addClass("h-100p m-unset")
	page.wrapper.append(`
    	<b-app
			class="w-100p h-100p bg-primary"
			url="/assets/bcore/apps/florist.json">
		</b-app>
  	`)
}